import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Projects" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.etsy.com/shop/LuminaArtLab",
          "title": "Generative art posters Etsy shop",
          "target": "_blank",
          "rel": "noopener noreferrer nofollow"
        }}>{`LuminaArtLab`}</a>{` generative art posters  `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/romandek/OmniFocus-Scripts",
          "title": "Link to GitHub repository of my OmniFocus scripts",
          "target": "_blank",
          "rel": "noopener noreferrer nofollow"
        }}>{`OmniFocus scripts`}</a>{` `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://questioneverything.substack.com",
          "title": "Link to Question everything newsletter on Substack",
          "target": "_blank",
          "rel": "noopener noreferrer nofollow"
        }}>{`Question Everything`}</a>{` newsletter (dropped)`}</li>
      <li parentName="ul">{`Learning German 🇩🇪`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      